export const ServiceUrl = {
  V2_LOGIN: {
    CHECK_USERNAME: "v2/login/check-username",
    CAPTCHA: "v1/captcha",
    VERIFY_CAPTCHA: "v2/login/verify-captcha",
    UPDATE_PASSWORD: "v2/login/update-pass",
  },

  LOGIN_V2: {
    CHECK: "/v2/admin/check",
    CHECK_USERNAME: "/v1/admin/login",
  },
  COURSES: {
    CATEGORY_CREATE: "v1/admin/course/category/create",
    CATEGORY_LIST: "v1/admin/course/category/list",
    CATEGORY_DELETE: "v1/admin/course/category/delete",
    CATEGORY_UPDATE: "v1/admin/course/category/update",

    LEVEL_CREATE: "v1/admin/course/level/create",
    LEVEL_LIST: "v1/admin/course/level/list",
    LEVEL_DELETE: "v1/admin/course/level/delete",
    LEVEL_UPDATE: "v1/admin/course/level/update",

    DEGREE_CREATE: "v1/admin/course/degree/create",
    DEGREE_LIST: "v1/admin/course/degree/list",
    DEGREE_DELETE: "v1/admin/course/degree/delete",
    DEGREE_UPDATE: "v1/admin/course/degree/update",

    DEPARTMENT_CREATE: "v1/admin/course/dept/create",
    DEPARTMENT_LIST: "v1/admin/course/dept/list",
    DEPARTMENT_DELETE: "v1/admin/course/dept/delete",
    DEPARTMENT_UPDATE: "v1/admin/course/dept/update",

    COURSE_CREATE: "v1/admin/course/create",
    COURSE_LIST: "v1/admin/course/list",
    COURSE_LIST_MININFO: "v1/admin/course/min-info",
    COURSE_DELETE: "v1/admin/course/delete",
    COURSE_UPDATE_BASIC: "v1/admin/course/update-basic",
    COURSE_UPDATE_CONTENT: "v1/admin/course/update-content",

    ATT_PERCENTAGE_SAVE: "v1/admin/course/save-att-percentage",
    ATT_PERCENTAGE_LIST: "v1/admin/course/list-att-percentage",
    ATT_PERCENTAGE_DELETE: "v1/admin/course/delete-att-percentage",
    ATT_PERCENTAGE_UPDATE: "v1/admin/course/update-att-percentage",
  },
  COLLEGES: {
    COUNTRY_CREATE: "v1/admin/country/create",
    COUNTRY_LIST: "v1/admin/country/list",
    COUNTRY_DELETE: "v1/admin/country/delete",
    COUNTRY_UPDATE: "v1/admin/country/update",

    STATE_CREATE: "v1/admin/state/create",
    STATE_LIST: "v1/admin/state/list",
    STATE_DELETE: "v1/admin/state/delete",
    STATE_UPDATE: "v1/admin/state/update",

    DISTRICT_CREATE: "v1/admin/district/create",
    DISTRICT_LIST: "v1/admin/district/list",
    DISTRICT_DELETE: "v1/admin/district/delete",
    DISTRICT_UPDATE: "v1/admin/district/update",

    CITY_CREATE: "v1/admin/city/create",
    CITY_LIST: "v1/admin/city/list",
    CITY_DELETE: "v1/admin/city/delete",
    CITY_UPDATE: "v1/admin/city/update",

    TYPE_CREATE: "v1/admin/college/type/create",
    TYPE_LIST: "v1/admin/college/type/list",
    TYPE_DELETE: "v1/admin/college/type/delete",
    TYPE_UPDATE: "v1/admin/college/type/update",

    COLLEGE_CREATE: "v1/admin/college/create",
    COLLEGE_LIST: "v1/admin/college/list",
    COLLEGE_DELETE: "v1/admin/college/delete",
    COLLEGE_UPDATE_BASIC: "v1/admin/college/update-basic",
    COLLEGE_UPDATE_CONTENT: "v1/admin/college/update-content",

    COLLEGE_ADD_COURSE: "v1/admin/college/add-course",
    COLLEGE_LIST_COURSES: "v1/admin/college/list-courses",
    COLLEGE_LIST_COURSES_BATCH_WISE: "v1/admin/college/list-courses-batch-wise",
    COLLEGE_DELETE_COURSE: "v1/admin/college/delete-course",
    COLLEGE_UPDATE_COURSE: "v1/admin/college/update-course",
    COLLEGE_UPDATE_IMAGE: "v1/admin/college/update-image",

    COLLEGE_LIST_CONTACTS: "v1/admin/college/contacts",
    COLLEGE_DELETE_CONTACTs: "v1/admin/college/delete-contact",
    COLLEGE_UPDATE_CONTACTS: "v1/admin/college/update-contacts",

    ORDER_DATA: "college/get_order",
  },
  DISTANCE_OLD: {
    IMPORT_DISTANCE_STUDENTS:
      "v1/admin/distanceold_import/import-distance-students",
    IMPORT_DISTANCE_SUBJECTS:
      "v1/admin/distanceold_import/import-distance-subjects",
    IMPORT_DISTANCE_RESULTS:
      "v1/admin/distanceold_import/import-distance-results",

    DISTANCE_RESULT_BATCH: "v1/admin/distanceold_grade/distance-result-batch",
    DISTANCE_GRADE_GENERATE:
      "v1/admin/distanceold_grade/distance-grade-generate",

    DISTANCE_CGP_GENERATE: "v1/admin/distanceold_grade/distance-cgp-generate",
    DISTANCE_SUMCG_GENERATE:
      "v1/admin/distanceold_grade/distance-sumcg-generate",

    LIST_BY_STUDENTS: "v1/admin/distanceold_marksheet/list",
    UPDATE_STUDENTS: "v1/admin/distanceold_marksheet/update",
    DELETE_STUDENTS: "v1/admin/distanceold_marksheet/delete",

    LIST_BY_SUBJECTS: "v1/admin/distanceold_subjects/list-subjects",
    UPDATE_SUBJECTS: "v1/admin/distanceold_subjects/update-subjects",

    LIST_BY_RESULTS: "v1/admin/distanceold_results/student-results",

    EXAM_MOTNS_LIST: "v1/admin/distanceold_marksheet/exam-months",
    MARKSHEET_BY_REGNO: "v1/admin/distanceold_marksheet/marksheet",
  },
  ADMISSION: {
    SETTINGS_MENU: "v1/admin/settings/menu-list",
    SETTINGS: "v1/settings",
    LIST_VARIABLE_SETTINGS: "v1/admin/settings/list-settings",
    UPDATE_VARIABLE_SETTINGS: "v1/admin/settings/update-settings",
    ADD_VARIABLE_SETTINGS: "v1/admin/settings/add-settings",

    LIST_SMS_TEMPLATES: "v1/admin/smstemplate/list-templates",
    UPDATE_SMS_TEMPLATES: "v1/admin/smstemplate/update-template",
    ADD_SMS_TEMPLATES: "v1/admin/smstemplate/add-template",
    DELETE_SMS_TEMPLATES: "v1/admin/smstemplate/delete-template",

    LIST_MAIL_TEMPLATES: "v1/admin/mailtemplate/list-templates",
    UPDATE_MAIL_TEMPLATES: "v1/admin/mailtemplate/update-template",
    ADD_MAIL_TEMPLATES: "v1/admin/mailtemplate/add-template",
    DELETE_MAIL_TEMPLATES: "v1/admin/mailtemplate/delete-template",

    USER_PROFILE: "v1/admin/users/my-profile",
    USER_CHANGE_PASSWORD: "v1/admin/users/change-password",

    NEW_USER_LOGIN: "v1/admin/users/save-user",
    LIST_USERS_LOGIN: "v1/admin/users/list-users",
    USER_STATUS_CHANGE: "v1/admin/users/status-change",
    REMOVE_USER_LOGIN: "v1/admin/users/remove-user",
    UPDATE_USER_LOGIN: "v1/admin/users/update-user",
    PASS_USER_LOGIN: "v1/admin/users/user-password",
    UPDATE_USER_PASSWORD: "v1/admin/users/update-user-password",

    SAVE_IMAGE_SETTINGS: "v1/admin/settings/add-image-settings",
    LIST_IMAGE_SETTINGS: "v1/admin/settings/list-image-settings",
    ADD_RATIO: "v1/admin/settings/add-ratio",
    DELETE_RATIO: "v1/admin/settings/delete-ratio",
  },

  SETTINGS: {
    SAVE_CASHBOOKS: "v1/admin/cashbooks/save",
    LIST_CASHBOOKS: "v1/admin/cashbooks/list",
    DELETE_CASHBOOK: "v1/admin/cashbooks/delete",
    UPDATE_CASHBOOK: "v1/admin/cashbooks/update",

    ACADEMIC_YEAR_CREATE: "v1/admin/settings/acyear/create",
    ACADEMIC_YEAR_LIST: "v1/admin/settings/acyear/list",
    ACADEMIC_YEAR_DELETE: "v1/admin/settings/acyear/delete",
    ACADEMIC_YEAR_UPDATE: "v1/admin/settings/acyear/update",

    COMMUNITY_CREATE: "v1/admin/settings/community/create",
    COMMUNITY_LIST: "v1/admin/settings/community/list",
    COMMUNITY_DELETE: "v1/admin/settings/community/delete",
    COMMUNITY_UPDATE: "v1/admin/settings/community/update",

    BLOOD_GROUP_CREATE: "v1/admin/settings/bgroup/create",
    BLOOD_GROUP_LIST: "v1/admin/settings/bgroup/list",
    BLOOD_GROUP_DELETE: "v1/admin/settings/bgroup/delete",
    BLOOD_GROUP_UPDATE: "v1/admin/settings/bgroup/update",

    SAVE_TIMETABLE: "v1/admin/settings/timetable/save-time-table",
    UPDATE_TIMETABLE: "v1/admin/settings/timetable/update-time-table",
    GET_TIMETABLE: "v1/admin/settings/timetable/get-timetable",
    REMOVE_TIMETABLE: "v1/admin/settings/timetable/remove",

    RELIGION_CREATE: "v1/admin/settings/religion/create",
    RELIGION_LIST: "v1/admin/settings/religion/list",
    RELIGION_DELETE: "v1/admin/settings/religion/delete",
    RELIGION_UPDATE: "v1/admin/settings/religion/update",

    MOTHER_TONGUE_CREATE: "v1/admin/settings/mothertongue/create",
    MOTHER_TONGUE_LIST: "v1/admin/settings/mothertongue/list",
    MOTHER_TONGUE_DELETE: "v1/admin/settings/mothertongue/delete",
    MOTHER_TONGUE_UPDATE: "v1/admin/settings/mothertongue/update",

    BATCH_CREATE: "v1/admin/settings/batch/create",
    BATCH_LIST: "v1/admin/settings/batch/list",
    BATCH_DELETE: "v1/admin/settings/batch/delete",
    BATCH_UPDATE: "v1/admin/settings/batch/update",
    SEMESTER_DATES: "v1/admin/settings/batch/semester-dates",
    UPDATE_SEMESTER_DATES: "v1/admin/settings/batch/update-semester-dates",

    SAVE_CASHBOOK: "v1/admin/settings/cashbook/create",
    LIST_CASHBOOK: "v1/admin/settings/cashbook/list",
    DELETE_CASHBOOK: "v1/admin/settings/cashbook/delete",
    UPDATE_CASHBOOK: "v1/admin/settings/cashbook/update",

    USER_LOGIN_CREATE: "v1/admin/settings/user-login/create",
    USER_LOGIN_LIST: "v1/admin/settings/user-login/list",
    USER_LOGIN_DELETE: "v1/admin/settings/user-login/delete",
    USER_LOGIN_UPDATE: "v1/admin/settings/user-login/update",

    UPDATE_EMPLOYEE: "v1/admin/settings/user-login/update-employee",
    CREATE_EMPLOYEE: "v1/admin/settings/user-login/create-employee",

    SAVE_USERROLE: "v1/admin/rbac/userrole/save",
    LIST_USERROLE: "v1/admin/rbac/userrole/list",
    DELETE_USERROLE: "v1/admin/rbac/userrole/delete",
    UPDATE_USERROLE: "v1/admin/rbac/userrole/update",

    SAVE_ROLE_MODULE: "v1/admin/rbac/roleaccess/save-module",
    DELETE_ROLE_MODULE: "v1/admin/rbac/roleaccess/delete-module",
    UPDATE_ROLE_MODULE: "v1/admin/rbac/roleaccess/update-module-access",
    UPDATE_USER_ACCESS: "v1/admin/rbac/roleaccess/update-user-access",
    LIST_ROLE_ACCESS: "v1/admin/rbac/roleaccess/access-list",
    UPDATE_ROLE_ACCESS: "v1/admin/rbac/roleaccess/update-role-access",
    LIST_ROLE_PERMISSIONS: "v1/admin/rbac/roleaccess/list-role-permissions",

    LIST_COMMUNITY: "v1/set/community",
    LIST_RELIGIONS: "v1/set/religions",
    LIST_OCCUPATION_CATEGORIES: "v1/set/occupation-categories",

    LIST_USER_LOGS: "v1/admin/logs/list-all",

    SAVE_GRADE_POINTS: "v1/admin/settings/gradePoint/save",
    LIST_GRADE_POINTS: "v1/admin/settings/gradePoint/list",
    UPDATE_GRADE_POINTS: "v1/admin/settings/gradePoint/update",
    DELETE_GRADE_POINTS: "v1/admin/settings/gradePoint/delete",

    PHD_BATCH_CREATE: "v1/admin/settings/batch/create-phd-batch",
    PHD_BATCH_LIST: "v1/admin/settings/batch/list-phd-batch",
    PHD_BATCH_DELETE: "v1/admin/settings/batch/delete-phd-batch",
    PHD_BATCH_UPDATE: "v1/admin/settings/batch/update-phd-batch",

    LIST_PHD_TASKS: "v1/admin/settings/tasks/list",
    CREATE_PHD_TASKS: "v1/admin/settings/tasks/create",
    UPDATE_PHD_TASKS: "v1/admin/settings/tasks/update",
    DELETE_PHD_TASKS: "v1/admin/settings/tasks/delete",

    LIST_MODE_OF_STUDY: "v1/admin/settings/mos/list",
    CREATE_MODE_OF_STUDY: "v1/admin/settings/mos/create",
    UPDATE_MODE_OF_STUDY: "v1/admin/settings/mos/update",
    DELETE_MODE_OF_STUDY: "v1/admin/settings/mos/delete",

    SAVE_DEPARTMENT: "v1/admin/settings/departments/save",
    LIST_DEPARTMENT: "v1/admin/settings/departments/list",
    DELETE_DEPARTMENT: "v1/admin/settings/departments/delete",
    UPDATE_DEPARTMENT: "v1/admin/settings/departments/update",
    UPDATE_DEPARTMENT_COURSE:
      "v1/admin/settings/departments/update-dept-course",
  },

  FIELD_SETTINGS: {
    NEW: "v1/field_settings/new",
    LIST: "v1/field_settings/all",
    REMOVE: "v1/field_settings/delete",
    UPDATE: "v1/field_settings/update",
  },
  SMS: {
    CHECK_BALANCE: "v1/admin/sms/balance",
  },

  WEBSITE_CMS: {
    SAVE_PAGE: "v1/admin/cms/page/save",
    LIST_PAGES: "v1/admin/cms/page/list",
    DELETE_PAGE: "v1/admin/cms/page/delete",
    GET_PAGE: "v1/admin/cms/page/get",
    GET_EVENT_GALLERY: "v1/admin/cms/page/get-event-gallery",
    UPDATE_PAGE: "v1/admin/cms/page/update",
    GET_EVENT_CATEGORY: "v1/admin/cms/page/get-event-category",

    SAVE_GALLERY: "v1/admin/cms/page/save-gallery",
    LIST_GALLERIES: "v1/admin/cms/page/list-galleries",
    DELETE_GALLERY: "v1/admin/cms/page/delete-gallery",
    LIST_GALLERY: "v1/admin/cms/page/list-gallery",
    UPDATE_GALLERY: "v1/admin/cms/page/update-gallery",

    HOME_PAGE_LIST: "v1/admin/cms/page/home-page-list",
    CHANGE_HOME_STATUS: "v1/admin/cms/page/change-home-status",
    UPDATE_COMPONENT_FILTER: "v1/admin/cms/page/update-component-filter",

    LIST_CATEGORY_NAMES: "v1/admin/cms/page/list-categories",

    FILES_LIST_DIRECTORY: "v1/admin/files/list-directory-v2",
    FILES_UPLOAD: "v1/admin/files/upload-files-v2",
    REMOVE_FILE: "v1/admin/files/remove-v2",
    CREATE_FOLDER: "v1/admin/files/create-folder-v2",

    UPDATE_MENU: "v1/admin/website-cms/update-menu",
    UPDATE_LINK: "v1/admin/website-cms/update-link",
  },

  /*  STUDENTS: {
    CREATE_STUDENT: "v1/admin/students/create",
    LIST_STUDENTS: "v1/admin/students/list",
    LIST_AUTONOMOUS_STUDENTS: "v1/admin/students/list-autonomous-students",
    UPDATE_STUDENT: "v1/admin/students/update",
    UPDATE_LEFT_LONG_ABSENT: "v1/admin/students/update-left-long-absent",
    REMOVE_LONG_ABSENT: "v1/admin/students/remove-long-absent",
    QUICK_EDIT: "v1/admin/students/quick-update",

    IMPORT_STUDENTS: "v1/admin/students/import",
    PROMOTE_STUDENTS: "v1/admin/students/promote",
    UPDATE_STUDENT_REGISTER_NO: "v1/admin/students/update-register-no",
    UPDATE_PROGRAM_WISE_SUBJECTS:
      "v1/admin/students/update-programwise-subjects",
    ALLOCATED_STUDENT_SUBJECTS: "v1/admin/students/students-subjects",
    ALLOCATION_LIST: "v1/admin/students/allocation-list",
    ALLOCATE_ARREAR_SUBJECTS: "v1/admin/students/allocate-arrear-subjects",
    SAVE_STUDENT_SUBJECTS: "v1/admin/students/save-student-subjects",
    DELETE_STUDENT_SUBJECT: "v1/admin/students/delete-student-subject",

    IMPORT_STUDENT_ATTENDANCE: "v1/admin/student/att/import",
    GET_STUDENT_ATTENDANCE_REPORT: "v1/admin/student/att/get",
    CHECK_STUDENT_ATTENDANCE: "v1/admin/student/att/check-student-attendance",
    SAVE_STUDENT_ATTENDANCE: "v1/admin/student/att/save-student-attendance",
    UPDATE_STUDENT_ATTENDANCE: "v1/admin/student/att/update-student-attendance",
    LIST_ATTENDANCE_RULES: "v1/admin/student/att/list-attendance-rules",
    GET_STUDENT_STRENGTH_REPORT: "v1/admin/student/report/get",

    LENGTH_EXAM_STUDENTS_COUNT: "v1/admin/students/length-exam-students-count",
    GET_EXAM_STUDENTS_COUNT: "v1/admin/students/exam-students-count",
    VIEW_SUBJECT_STUDENTS: "v1/admin/students/view-subject-students",

    SAVE_PROVISIONAL_CERTIFICATE: "v1/admin/student/provisional/save",
    LIST_PROVISIONAL_CERTIFICATES: "v1/admin/student/provisional/list-all",
    PROVISIONAL_ELIGIBILITY_COUNT:
      "v1/admin/student/provisional/provisional-eligibility-count",
    CHECK_PROVISIONAL_ELIGIBILITY:
      "v1/admin/student/provisional/check-provisional-eligibility",
    GET_GOLD_MEDALISTS: "v1/admin/student/provisional/get-gold-medalists",
    AUTO_PROVISIONAL_ELIGIBILITY_COUNT:
      "v1/admin/student/provisional/auto-provisional-eligibility-count",
    CHECK_AUTO_PROVISIONAL_ELIGIBILITY:
      "v1/admin/student/provisional/check-auto-provisional-eligibility",
    GET_PROVISIONAL_CERT:
      "v1/admin/student/provisional/get-provisional-certificate",
    GET_PROVISIONAL_CERT_AUTO:
      "v1/admin/student/provisional/get-provisional-certificate-auto",
    SAVE_AUTONOMOUS_CGPA: "v1/admin/student/provisional/save-autonomous-cgpa",
    SAVE_PROVISIONAL_LOG: "v1/admin/student/provisional/save-provisional-log",

    LIST_FEE_TEMPLATES: "v1/admin/student/bonaifed/list-fees",
    DELETE_BONAFIED_CERTIFICATE: "v1/admin/student/bonaifed/delete",

    SAVE_RE_ADMISSION: "v1/admin/student/save-readmission",
  }, */

  STUDENTS: {
    SEARCH_BY_REGISTER_NO: "v1/admin/student_search/search-student-by-no",
    SEARCH_BY_REGISTER_NO_TC: "v1/admin/student_search/search-student-by-no-tc",
    SEARCH_BY_OVERALL_REPORT: "v1/admin/student_search/search-student-overall",
    OVERALL_TEMPLATE_REPROT: "v1/admin/student_search/save-student-template",
    LIST_TEMPLATE: "v1/admin/student_search/list-template",
    ID_CARD_DOWNLOAD: "v1/admin/id_card_download/id-card-download",
    ZIP: "v1/admin/id_card_download/zip",
    SEARCH_BY_NAME: "v1/admin/student_search/search-by-name",
    INCOMPLETE_PROFILES: "v1/admin/student_search/incomplete",
    QUICK_EDIT_LIST: "v1/admin/student_search/quick-edit-list",
    QUICK_EDIT_UPDATE_STUDENT:
      "v1/admin/student/quickedittype/update-student-quick-edit",
    LIST_QUICK_EDIT_CONFIG:
      "v1/admin/student/quickedittype/list-quick-edit-config",
    STUDENT_ADMISSION_REGISTER: "v1/admin/student/admission-register",
    UG_STUDENT_ADMISSION_REGISTER_COUNT:
      "v1/admin/student/ug-admission-register-count",
    PG_STUDENT_ADMISSION_REGISTER_COUNT:
      "v1/admin/student/pg-admission-register-count",
    STUDENT_ADMISSION_NO: "v1/admin/student/admission-no",
    SAVE_STUDENT_FORM_TWO: "v1/admin/student/add-student-form-two",
    SAVE_BUS_ROUTE: "v1/admin/student/save-vehicle",
    TRANSPORT_DETAILS: "v1/admin/student_search/transport-details",
    PROMOTION_DETAILS: "v1/admin/student_search/promotion-details",
    SAVE_PROMOTION: "v1/admin/student/save-promotion",
    REMOVE_PROMOTION: "v1/admin/student/remove-promotion",
    UPDATE_BULK_FIELD: "v1/admin/student/update-bulk-field",
    SAVE_RE_ADMISSION: "v1/admin/student/save-readmission",

    SAVE_STUDENT: "v1/admin/student/save-student",
    UPDATE_STUDENT: "v1/admin/student/update-student",
    UPDATE_HOSTEL_DATES: "v1/admin/student/update-hostel-dates",
    DELETE_STUDENT: "v1/admin/student/delete",

    STUDENT_STRENGTH: "v1/admin/student_report/current-strength",
    STRENGTH_REPORT: "v1/admin/student_report/strength-report",
    STUDENT_COMMNUITY_REPORT:
      "v1/admin/student_community_report/student-commnuity-report",

    ADMISSION_ALLOCATION_SAVE: "v1/admin/admission-allocation/save",
    ADMISSION_ALLOCATION_LIST: "v1/admin/admission-allocation/list",
    ADMISSION_ALLOCATION_DELETE: "v1/admin/admission-allocation/delete",
    ADMISSION_ALLOCATION_UPDATE: "v1/admin/admission-allocation/update",

    STUDENTS_PROFILE_INCOMPLETE_COUNT:
      "v1/admin/student_report/incomplete-count",
    STUDENTS_PROFILE_INCOMPLETE_LIST:
      "v1/admin/student_report/incomplete-students",
    INCOMPLETE_REPORT: "v1/admin/student_report/incomplete-report",

    IMPORT_STUDENT: "v1/admin/importstudent/save",
    BULK_PHOTO_UPLOAD: "v1/admin/student/bulk-photo-upload",
    BULK_UPDATE: "v1/admin/student/bulk-update",
    UPDATE_TC_DATES: "v1/admin/student/tc-date-update",
    SAVE_STUDENT_TC: "v1/admin/student/save-tc",
    GET_TC_NO: "v1/admin/student/tc-no",
    SAVE_STUDENT_BULK_TC: "v1/admin/student/save-bulk-tc",
    LIST_STUDENT_TC: "v1/admin/student/list-tc",
    LIST_PENDING_DETAILS: "v1/admin/student/list-tc-pending-details",
    COUNT_PENDING_DETAILS: "v1/admin/student/count-tc-pending-details",
    SAVE_APPLIED_DATE: "v1/admin/student/save-applied-date",
    UPDATE_TRANSPORT_ID: "v1/admin/student/update-transport-id",

    NEW_CERTIFICATE: {
      STUD_PROF_DATA: "v1/admin/student/new_certificate/list_stud_profile_data",
      SAVE_NEW_CERT: "v1/admin/student/new_certificate/save_new_certificate",

      LIST_CERT_REPORT:
        "v1/admin/student/new_certificate/list_certificate_report",
      DELETE_CERT_REPORT:
        "v1/admin/student/new_certificate/delete_certificate_report",

      LIST_CERT_TYPES: "v1/admin/student/new_certificate/list_cert_types",
      SAVE_CERT_TYPES: "v1/admin/student/new_certificate/save_cert_types",
      UPDATE_CERT_TYPES: "v1/admin/student/new_certificate/update_cert_types",
      DELETE_CERT_TYPES: "v1/admin/student/new_certificate/delete_cert_types",

      LIST_CERT_TEMPLATES:
        "v1/admin/student/new_certificate/list_cert_templates",
      SAVE_CERT_TEMPLATES:
        "v1/admin/student/new_certificate/save_cert_templates",
      UPDATE_CERT_TEMPLATES:
        "v1/admin/student/new_certificate/update_cert_templates",
      DELETE_CERT_TEMPLATES:
        "v1/admin/student/new_certificate/delete_cert_templates",
    },

    SAVE_BONAFIED_CERTIFICATE: "v1/admin/student/bonaifed/save",
    LIST_BONAFIED_CERTIFICATES: "v1/admin/student/bonaifed/list-all",
    LIST_FEE_TEMPLATES: "v1/admin/student/bonaifed/list-fees",
    DELETE_BONAFIED_CERTIFICATE: "v1/admin/student/bonaifed/delete",
    SAVE_VERIFICATION_CERTIFICATE: "v1/admin/student/verification/save",
    LIST_VERIFICATION_CERTIFICATES: "v1/admin/student/verification/list-all",

    /* DELETE_VERIFICATION_CERTIFICATE: "v1/admin/student/bonaifed/delete", */
    SAVE_CONDUCT_CERTIFICATE: "v1/admin/student/conduct/save",
    LIST_CONDUCT_CERTIFICATES: "v1/admin/student/conduct/list-all",
    DELETE_CONDUCT_CERTIFICATE: "v1/admin/student/conduct/delete",
    SAVE_MEDIUM_CERTIFICATE: "v1/admin/student/medium/save",
    LIST_MEDIUM_CERTIFICATES: "v1/admin/student/medium/list-all",
    DELETE_MEDIUM_CERTIFICATE: "v1/admin/student/medium/delete",
    LIST_VERIFY_CERTIFICATE: "v1/admin/student/verification/list",
    SAVE_VERIFY_CERTIFICATE: "v1/admin/student/verification/save",
    SAVE_ATTENDANCE_CERTIFICATE: "v1/admin/student/attendancecertificate/save",
    LIST_ATTENDANCE_CERTIFICATES:
      "v1/admin/student/attendancecertificate/list-all",
    LIST_GENUINE_CERTIFICATE: "v1/admin/student/genuine_cert/list-all",
    SAVE_GENUINE_CERTIFICATE: "v1/admin/student/genuine_cert/save",
    MY_ATTENDANCE_DATA: "v1/admin/student/attendance/my-attendance-data",
    LIST_UNMARKED_ATTENDANCE: "v1/admin/student/attendance/unmarked-list",

    SAVE_COMPLETION_CERTIFICATE: "v1/admin/student/completion/save",
    LIST_COMPLETION_CERTIFICATES: "v1/admin/student/completion/list-all",
    DELETE_COMPLETION_CERTIFICATE: "v1/admin/student/completion/delete",

    LIST_BATCHES_COURSES: "v1/admin/stu_daily_report/list-batches-and-courses",
    LIST_ATTENDANCE: "v1/admin/stu_daily_report/list-attendance",
    LIST_ATTENDANCE_RECORD: "v1/admin/stu_daily_report/list-attendance-record",

    SAVE_ID_CARD: "v1/admin/student/idcard/save",
    LIST_ID_CARD: "v1/admin/student/idcard/list-all",
    DELETE_ID_CARD: "v1/admin/student/idcard/delete",

    LEARNING_OUTCOME_SAVE: "v1/admin/lerning_outcome/save",
    LEARNING_OUTCOME_UPDATE: "v1/admin/lerning_outcome/update",
    LEARNING_OUTCOME_UPDATE_ATTACHEMENT:
      "v1/admin/lerning_outcome/update-attachement",
    LEARNING_OUTCOME_LIST: "v1/admin/lerning_outcome/list",
    LEARNING_OUTCOME_DELECT: "v1/admin/lerning_outcome/delect",
    LEARNING_OUTCOME_LIST_STUDENT: "v1/admin/lerning_outcome/list-student",
    LEARNING_OUTCOME_STAFF_SUBJECT:
      "v1/admin/lerning_outcome/list-staffbysubject",
    LEARNING_OUTCOME_STUDENT_SAVE: "v1/admin/lerning_outcome/student-save",
    LEARNING_OUTCOME_STUDENT_VIEW: "v1/admin/lerning_outcome/view-student",
    LEARNING_OUTCOME_LIST_COURSE: "v1/admin/lerning_outcome/listallcourse",
    LEARNING_OUTCOME_STUDENT_DELECT:
      "v1/admin/lerning_outcome/view-student-delect",

    LIST_TARGET_MARK: "v1/admin/target_marks/list",
    LIST_TARGET_MARK_STUDENT: "v1/admin/target_marks/list-student",

    DATE_WISE_ATTENDANCE: "v1/admin/student/attendance/get",
    UPDATE_DAY_ATTENDANCE: "v1/admin/student/attendance/update",
    SAVE_LONG_ABSENT_STUDENT: "v1/admin/student/attendance/save-long-absent",
    LONG_ABSENT_STUDENTS: "v1/admin/student/attendance/long-absentee",
    GET_MARKED_GROUP_BY_DAYHOUR: "v1/admin/student/attendance/get-marked",
    DELETE_LONG_ABSENT_STUDENT:
      "v1/admin/student/attendance/delete-long-absentee",
    DAy_WISE_ENTRY_RECORDS: "v1/admin/student/attendance/get-day-records",
    DELETE_DAY_ENTRY: "v1/admin/student/attendance/delete-day-records",
    MARK_BULK_OD: "v1/admin/student/attendance/mark-bulkod",
    DAY_WISE_ATTENDANCE_COUNT: "v1/admin/student/attendance/day-wise-count",
    SAVE_SINGLE_OD_ENTRY: "v1/admin/student/attendance/single-od-update",

    LIST_QUICK_EDIT_TYPE: "v1/admin/student/quickedittype/list",
    ADD_QUICK_EDIT_TYPE: "v1/admin/student/quickedittype/save",
    EDIT_QUICK_EDIT_TYPE: "v1/admin/student/quickedittype/update",
    REMOVE_QUICK_EDIT_TYPE: "v1/admin/student/quickedittype/delete",

    ATTENDANCE_COUNT_REPORT: "v1/admin/student/attendance/reports",
    ATTENDANCE_MONTHLY_REPORT: "v1/admin/student/attendance/monthly-report",
    STUDENT_SMS_LOGS: "v1/admin/student/attendance/sms-logs",
    TODAY_FULL_ABSENT_COUNT: "v1/admin/student/attendance/full-absent-count",
    COURSE_LACK_REPORT: "v1/admin/student/attendance/course-lack-report",
    OD_REGISTER_LOGS: "v1/admin/student/attendance/od-logs",
    SEND_SMS_ABSENTES_MANUALLY:
      "v1/admin/student/attendance/send-sms-to-absentees",

    ALLOCATED_CLASS_WISE_SUBJECTS: "v1/admin/student/subject/allocated-list",
    ALLOCATE_CLASS_WISE_SUBJECTS: "v1/admin/student/subject/allocate",
    STUDENTS_SUBJECT_LIST: "v1/admin/student/subject/students-allocated-list",
    DELETE_STUDENT_SUBJECT: "v1/admin/student/subject/delete-student-subject",
    BULK_DELETE_STUDENT_SUBJECT: "v1/admin/student/subject/bulk-delete",
    BULK_UPDATE_STUDENT_SUBJECT: "v1/admin/student/subject/bulk-update",
    FIND_COURSE_SUBJECT: "v1/admin/student/subject/find",
    UPDATE_STUDENT_ALLOCATED_SUBJECT: "v1/admin/student/subject/change-subject",
    SAVE_STUDENT_ALLOCATED_SUBJECT: "v1/admin/student/subject/add-subject",
    DELETE_ALLOCATED_SUBJECTS: "v1/admin/student/subject/delete-allocated",
    STUDENT_SUBJECT_MANUAL_IMPORT: "v1/admin/student/subject/manual-import",
    MERGE_STUDENT_POLLING_LOGS: "v1/admin/student/subject/merge-polling-logs",

    STUDENTS_SUBJECT_UNALLOCATED_LIST:
      "v1/admin/student/subject/students-unallocated-list",

    REMOVE_LEFT: "v1/admin/student/delete-left",
    UPDATE_LEFT: "v1/admin/student/update-left",

    SAVE_HOSTEL_ADMISSION: "v1/admin/hostel/admission/save",
    LIST_HOSTEL_STUDENTS: "v1/admin/hostel/admission/list",
    DELETE_HOSTEL_STUDENT: "v1/admin/hostel/admission/delete",
    UPDATE_HOSTEL_STUDENT: "v1/admin/hostel/admission/update",
    CHANGE_STUDENT_ROOM: "v1/admin/hostel/admission/change-room",
    SAVE_BULK_HOSTEL_ADMISSION: "v1/admin/hostel/admission/bulk-save",
    HOSTEL_ATTENDANCE_MONTHLY_REPORT:
      "v1/admin/hostel/admission/monthly-report",

    STUDENT_TRANSPORT_LIST: "v1/admin/student/trasnport/students-list",

    BULK_ADDON_ADMISSION: "v1/admin/student/bulk-addon-admission",

    NEW_STUDENT_ADMISSION: "v1/admin/student/admission",
    REQUEST: {
      LIST: "v1/admin/student/service_request/list-all",
      EDIT: "v1/admin/student/service_request/edit-request",
      LIST_STATUS: "v1/admin/student/service_request/list-request-status?id=",
    },
    //attendance routes added by karan
    CLASS_TAKEN_REPORT: "v1/admin/attendance/class-taken-report",
    TIMETABLE_ON_DATE: "v1/admin/attendance/today-time-table",
    LIST_TODAY_CLASS: "v1/admin/attendance/list-classes-by-staff-and-date",
    LIST_SUBJECTS_AS_PER_COURSE:
      "v1/admin/attendance/list-subjects-as-per-course",
    LIST_STAFFS_AS_PER_SUBJECT:
      "v1/admin/attendance/list-staffs-as-per-subject",
    LIST_SUBJECTS_AS_PER_STAFFS:
      "v1/admin/attendance/list-subjects-as-per-staffs",
    LIST_CLASS_TAKEN_COUNT: "v1/admin/attendance/list-class-taken-count",
    BATCH_LIST_STUDENT_TC: "v1/admin/student/batch-list-tc",

    GET_MONTHLY_TIMETABLE: "v1/admin/attendance/monthly-wise-time-table",
    GET_MONTHLY_ATT_DATA: "v1/admin/attendance/monthly-wise-att-data",

    STUDENT_STRENGTH_REPORT_II:
      "v1/admin/student-strength-report/statistics-strength",

    HSC_MARKS_UPDATE: "v1/admin/hscmarks/update",
    HSC_MARK_LIST: "v1/admin/student/hscmarks/list",
    LIST_HSC_SUBJECTS: "v1/admin/student/hscmarks/list-subject",
    UPDATEMARK: "v1/admin/student/hscmarks/update-mark",
    UPDATE_STUDENT_SCHOOL_DETAILS:
      "v1/admin/student/hscmarks/update-student-school-details",

    UPDATE_UG_MARK: "v1/admin/student/hscmarks/update-ug-marks",
    UG_MARK_LIST: "v1/admin/student/hscmarks/list-ug",

    SAVE_HSC_SUBJECT: "v1/admin/student/hscmarks/save-subject",
    SAVE_UG_SUBJECT: "v1/admin/student/hscmarks/save-ug-subject",
    LIST_UG_SUBJECTS: "v1/admin/student/hscmarks/list-ug-subject",
  },

  PHD_STUDENTS: {
    IMPORT_SCHOLARS: "v1/admin/phd-students/import",
    CREATE_STUDENT: "v1/admin/phd-students/create",
    LIST_STUDENTS: "v1/admin/phd-students/list",
    LIST_AUTONOMOUS_STUDENTS: "v1/admin/phd-students/list-autonomous-students",
    UPDATE_STUDENT: "v1/admin/phd-students/update",
    UPDATE_LEFT_LONG_ABSENT: "v1/admin/phd-students/update-left-long-absent",
    REMOVE_LONG_ABSENT: "v1/admin/phd-students/remove-long-absent",
    QUICK_EDIT: "v1/admin/phd-students/quick-update",

    UPDATE_DEPARTMENT_WISE_TASKS:
      "v1/admin/phd-students/update-departmentwise-tasks",
    SAVE_STUDENT_TASKS: "v1/admin/phd-students/save-student-tasks",
    ALLOCATION_LIST: "v1/admin/phd-students/task-allocation-list",
    DELETE_STUDENT_TASK: "v1/admin/phd-students/delete-student-task",
    ALLOCATED_STUDENT_TASKS: "v1/admin/phd-students/students-tasks",

    LIST_ELIGIBLE_PROMOTION_STUDENTS:
      "v1/admin/phd-students/eligible-promotion-students",
    PROMOTE_PHD_STUDENT: "v1/admin/phd-students/promote",

    SAVE_SCHOLAR_SUBJECTS: "v1/admin/phd-students/save-scholar-subjects",
    LIST_SCHOLAR_SUBJECTS: "v1/admin/phd-students/list-scholar-subjects",
    DELETE_SCHOLAR_SUBJECTS: "v1/admin/phd-students/delete-scholar-subjects",

    LIST_REQUESTS: "v1/admin/phd-student/request/list-requests",
  },

  PHD_GUIDES: {
    CREATE_GUIDE: "v1/admin/phd-guides/create",
    LIST_GUIDES: "v1/admin/phd-guides/list",
    COUNT_GUIDES: "v1/admin/phd-guides/count-guides",
    REMOVE_GUIDE: "v1/admin/phd-guides/delete",
    UPDATE_GUIDE: "v1/admin/phd-guides/update",

    VIEW_PASSWORD: "v1/admin/phd-guides/view-password",
    UPDATE_PASSWORD: "v1/admin/phd-guides/update-password",

    EMPLOYEE_NEW_CODE: "v1/admin/phd-guides/new-emp-code",

    LIST_GUIDES_SCHOLARS: "v1/admin/phd-guides/list-guide-scholars",
    ALLOCATE_SCHOLAR: "v1/admin/phd-guides/allocate-scholar",
    DELETE_GUIDE_SCHOLAR: "v1/admin/phd-guides/delete-guide-scholar",
  },
  ALUMNI_STUDENTS: {
    CREATE_STUDENT: "v1/admin/alumni/alumni-students/save",
    LIST_STUDENTS: "v1/admin/alumni/alumni-students/list",
    DELETE_STUDENTS: "v1/admin/alumni/alumni-students/delete",

    IMPORT_ALUMNI_MARKS: "v1/admin/alumni/alumni-students/import-alumni-marks",
    UPDATE_ALUMNI_MARKS: "v1/admin/alumni/alumni-students/update-alumni-marks",
    MARKSHEET_BY_REGNO: "v1/admin/alumni/alumni-students/marksheet",
  },
  SUBJECTS: {
    SAVE_SUBJECT_SCHEME: "v1/admin/subjects/subjectscheme/save",
    LIST_SUBJECT_SCHEME: "v1/admin/subjects/subjectscheme/list",
    REMOVE_SUBJECT_SCHEME: "v1/admin/subjects/subjectscheme/delete",
    UPDATE_SUBJECT_SCHEME: "v1/admin/subjects/subjectscheme/update",

    CREATE_NATURE: "v1/admin/subjects/nature/create",
    LIST_NATURE: "v1/admin/subjects/nature/list",
    DELETE_NATURE: "v1/admin/subjects/nature/delete",
    UPDATE_NATURE: "v1/admin/subjects/nature/update",

    CREATE_SUBJECT: "v1/admin/subjects/sub/create",
    LIST_SUBJECT: "v1/admin/subjects/sub/list",
    DELETE_SUBJECT: "v1/admin/subjects/sub/delete",
    UPDATE_SUBJECT: "v1/admin/subjects/sub/update",
    IMPORT_SUBJECT: "v1/admin/subjects/sub/import-subjects",

    UPDATE_SUBJECT_TO_BATCH: "v1/admin/subjects/sub/update-subject-to-batch",
    LIST_SUBJECTS_IN_BATCH: "v1/admin/subjects/sub/subjects-in-batch",
    UPDATE_SUBJECT_TO_SEMESTER:
      "v1/admin/subjects/sub/update-subject-to-semester",
    LIST_SUBJECTS_IN_SEMESTER: "v1/admin/subjects/sub/subjects-in-semester",
    DELETE_SUBJECT_IN_SEMESTER:
      "v1/admin/subjects/sub/delete-subject-in-semester",
    LIST_ARREAR_SUBJECTS_IN_MARKS:
      "v1/admin/subjects/sub/arrear-subjects-in-marks",
    DELETE_SUBJECT_IN_BATCH: "v1/admin/subjects/sub/delete-subject-in-batch",

    ALLOCATED_SUBJECTS: "v1/admin/subjects/sub/allocated-subjects",
    ALLOCATED_SUBJECTS_BY_ATTEMPT:
      "v1/admin/subjects/sub/allocated-subjects-by-attempt",

    LIST_COURSE_SUBJECTS: "v1/admin/subjects/sub-allocate/list-by-course",
    SAVE_COURSE_SUBJECT: "v1/admin/subjects/sub-allocate/save",
    COURSE_SUBJECT_BULK_SAVE: "v1/admin/subjects/sub-allocate/bulk-save",
    DELETE_ALLOCATED_COURSE_SUBJECTS:
      "v1/admin/subjects/sub-allocate/delete-allocation",

    BULK_SAVE_STAFFSUBJECT: "v1/admin/subjects/staffsubject/bulk-save",
    LIST_STAFFSUBJECT: "v1/admin/subjects/staffsubject/list",
    SAVE_STAFFSUBJECT: "v1/admin/subjects/staffsubject/save",
    DELETE_STAFFSUBJECT: "v1/admin/subjects/staffsubject/delete",

    //fee

    LIST_REVALUATION_FEE: "v1/admin/subjects/fee/list-revaluation-fee",
    ASSIGN_REVALUATION_FEE: "v1/admin/subjects/fee/assign-revaluation-fee",

    LIST_VALUATION_FEE: "v1/admin/subjects/fee/list-valuation-fee",
    ASSIGN_VALUATION_FEE: "v1/admin/subjects/fee/assign-valuation-fee",

    ALLOCATED_PHD_SUBJECTS: "v1/admin/subjects/sub/allocated-phd-subjects",
  },
  EXAMS: {
    CREATE_EXAM_MONTH: "v1/admin/exam/month/create",
    LIST_EXAM_MONTH: "v1/admin/exam/month/list",
    DELETE_EXAM_MONTH: "v1/admin/exam/month/delete",
    UPDATE_EXAM_MONTH: "v1/admin/exam/month/update",

    LIST_NOMINAL_ROLL: "v1/admin/exam/nominal/list",

    UPDATE_EXAM_DATE: "v1/admin/exam/dates/update-date",
    UPDATE_CLG_FEE_DATE: "v1/admin/exam/dates/update-clg-fee-date",
    UPDATE_REVALUATION_DATE: "v1/admin/exam/dates/update-revaluation-date",
    LIST_EXAM_DATES: "v1/admin/exam/dates/list-dates",
    LIST_CLG_FEE_DATES: "v1/admin/exam/dates/list-clg-fee-dates",
    LIST_REVALUATION_DATES: "v1/admin/exam/dates/list-revaluation-dates",

    UPDATE_EXAM_FINE_CATEGORY: "v1/admin/exam/dates/update-category",
    UPDATE_REVALUATION_FEE_CATEGORY:
      "v1/admin/exam/dates/update-revaluation-category",

    CHECK_INTERNAL_MARKS_ENTERED: "v1/admin/exam/marks/check-internal-filled",

    SAVE_INTERNAL_MARKS: "v1/admin/exam/marks/save-internal-marks",
    SAVE_ARREAR_INTERNAL_MARKS:
      "v1/admin/exam/marks/save-arrear-internal-marks",
    GET_INTERNAL_MARKS: "v1/admin/exam/marks/get-internal-marks",
    GET_MARKSHEET_MARKS: "v1/admin/exam/marks/get-mark-sheet-marks",

    REVALUATION_STUDENTS_LIST:
      "v1/admin/exam/revaluation/revaluation-students-list",
    REVALUATION_SUBJECTS_COUNT:
      "v1/admin/exam/revaluation/revaluation-subjects-count",
    REVALUATION_SUBJECTS_LIST:
      "v1/admin/exam/revaluation/revaluation-subjects-list",
    SAVE_REVALUATION_MARKS: "v1/admin/exam/revaluation/save-revaluation-marks",
    SAVE_REVALUATION_MARKS_BY_DUMMYNO:
      "v1/admin/exam/revaluation/save-revaluation-marks-by-dummyno",
    GET_RESULT_MARKS: "v1/admin/exam/result/get-result-marks",
    PUBLISH_RESULT: "v1/admin/exam/result/publish-result",
    LIST_ARREARS: "v1/admin/exam/result/arrears-list",

    TERM_WISE_INTERNAL_MARKS: "v1/admin/exam/marks/term-internal-marks",
    UPDATE_MARKS: "v1/admin/exam/marks/update-marks",
    UPDATE_MARKS_GRADE: "v1/admin/exam/marks/update-marks-grade",
    UPDATE_SUMCG: "v1/admin/exam/marks/update-sumcg",
    UPDATE_CGP: "v1/admin/exam/marks/update-cgp",
    UPDATE_CGPA: "v1/admin/exam/marks/update-cgpa",
    UPDATE_CLASS: "v1/admin/exam/marks/update-class",
    SAVE_EXTERNAL_MARKS: "v1/admin/exam/marks/save-external-marks",
    SAVE_GRACE_MARKS: "v1/admin/exam/marks/save-grace-marks",

    IMPORT_MARKS: "v1/admin/exam/markimport/import-marks",
    IMPORT_ARREAR_MARKS: "v1/admin/exam/markimport/import-arrear-marks",
    IMPORT_LOGS: "v1/admin/exam/markimport/mark-import-logs",
    VIEW_LOGS: "v1/admin/exam/markimport/mark-import-log",

    CHECK_MARKS_ENTERED: "v1/admin/exam/grade/check-marks-entered",
    CHECK_GRADE_GENERATED: "v1/admin/exam/grade/check-grade-generated",
    CHECK_CGPA_GENERATED: "v1/admin/exam/grade/check-cgpa-generated",
    COUNT_OLD_BPES: "v1/admin/exam/grade/count-old-bpes-marks",
    OLD_BPES_CGPA_UPDATE: "v1/admin/exam/grade/old-bpes-cgpa-update",
    OLD_BPES_CLASS_UPDATE: "v1/admin/exam/grade/old-bpes-class-update",

    IMPORT_EXTERNAL_DUMMY_NO: "v1/admin/exam/dummyno/import",
    LIST_EXTERNAL_DUMMY_NOS: "v1/admin/exam/dummyno/list-dummy-nos",
    UPDATE_EXTERNAL_MARKS_BY_DUMMYNO: "v1/admin/exam/dummyno/save-marks",
    LIST_SCORING_HISTORY: "v1/admin/exam/dummyno/scoring-history",
    LIST_MARKS_BY_DUMMYNO: "v1/admin/exam/dummyno/marks-by-dummyno",
    UPDATE_MARKS_BY_DUMMYNO: "v1/admin/exam/dummyno/update-marks",

    LIST_DUMMY_NO_STUDENTS: "v1/admin/exam/dummyno/list-dummy-no-students",

    CHECK_DUMMY_NO: "v1/admin/exam/dummyno/check-dummy-no",
    GENERATE_DUMMY_NO: "v1/admin/exam/dummyno/generate-dummy-no",
    DUMMY_NO_LIST: "v1/admin/exam/dummyno/dummy-no-list",
    BUNDLE_NO_COUNT: "v1/admin/exam/dummyno/bundle-no-count",
    BUNDLE_NO_LIST: "v1/admin/exam/dummyno/bundle-no-list",

    CHECK_ATTENDANCE_ENTERED:
      "v1/admin/exam/attendance/check-attendance-entered",
    EXAM_ATTENDANCE_STUDENTS: "v1/admin/exam/attendance/attendance-students",
    SAVE_EXAM_ATTENDANCE: "v1/admin/exam/attendance/save-exam-attendance",
    LIST_EXAM_ATTENDANCE: "v1/admin/exam/attendance/list-exam-attendance",

    LIST_BACKLOG_SUBJECTS: "v1/admin/exam/backlog/backlog-subjects-in-marks",
    COUNT_BACKLOG_SUBJECTS: "v1/admin/exam/backlog/count-subjects-in-marks",
    ALLOCATE_BACKLOG_SUBJECTS:
      "v1/admin/exam/backlog/allocate-backlog-subjects",
    ASSIGN_BACKLOG_INT_MARKS: "v1/admin/exam/backlog/assign-backlog-int-marks",
    UPDATE_STUDENT_SUPPLEMENTARY_STATUS:
      "v1/admin/exam/backlog/update-supplementary-status",

    //Reports
    PASSOUT_PERCENTAGE: "v1/admin/exam/reports/passout-percentage",
    VALUATION_REPORT: "v1/admin/exam/reports/valuation-report",
  },
  PHD_EXAMS: {
    UPDATE_TASK_STATUS: "v1/admin/phd-exam/activities/update-task-status",

    LIST_PHD_EXAM_MONTHS: "v1/admin/phd-exam/months/list",
    CREATE_PHD_EXAM_MONTH: "v1/admin/phd-exam/months/create",
    DELETE_PHD_EXAM_MONTH: "v1/admin/phd-exam/months/delete",
    UPDATE_PHD_EXAM_MONTH: "v1/admin/phd-exam/months/update",

    LIST_DUMMY_NO_PHD_STUDENTS:
      "v1/admin/phd-exam/phd-dummyno/list-dummy-no-phd-students",
    CHECK_PHD_DUMMY_NO: "v1/admin/phd-exam/phd-dummyno/check-phd-dummy-no",
    GENERATE_PHD_DUMMY_NO:
      "v1/admin/phd-exam/phd-dummyno/generate-phd-dummy-no",
    PHD_BUNDLE_NO_COUNT: "v1/admin/phd-exam/phd-dummyno/phd-bundle-no-count",
    PHD_BUNDLE_NO_LIST: "v1/admin/phd-exam/phd-dummyno/phd-bundle-no-list",

    SAVE_PHD_MARKS: "v1/admin/phd-exam/phd-dummyno/save-phd-marks",
    LIST_PHD_DUMMY_NOS: "v1/admin/phd-exam/phd-dummyno/list-phd-dummy-nos",
    SAVE_PHD_MARKS_BY_DUMMYNO:
      "v1/admin/phd-exam/phd-dummyno/save-phd-marks-by-dummy-no",
    LIST_PHD_MARKS_HISTORY: "v1/admin/phd-exam/phd-dummyno/phd-marks-history",
  },
  PAYMENTS: {
    CREATE_GATEWAY: "v1/admin/payments/gateway/create",
    LIST_GATEWAY: "v1/admin/payments/gateway/list",
    DELETE_GATEWAY: "v1/admin/payments/gateway/delete",
    UPDATE_GATEWAY: "v1/admin/payments/gateway/update",

    CREATE_SUBACCOUNT: "v1/admin/payments/subaccount/create",
    LIST_SUBACCOUNT: "v1/admin/payments/subaccount/list",
    DELETE_SUBACCOUNT: "v1/admin/payments/subaccount/delete",
    UPDATE_SUBACCOUNT: "v1/admin/payments/subaccount/update",

    CREATE_CATEGORY: "v1/admin/payments/category/create",
    CREATE_COLLEGE_CATEGORY: "v1/admin/payments/category/create-clg-category",
    LIST_CATEGORY: "v1/admin/payments/category/list",
    LIST_COLLEGE_CATEGORY: "v1/admin/payments/category/college-cat-list",
    DELETE_CATEGORY: "v1/admin/payments/category/delete",
    DELETE_COLLEGE_CATEGORY: "v1/admin/payments/category/delete-clg-category",
    UPDATE_CATEGORY: "v1/admin/payments/category/update",
    UPDATE_COLLEGE_CATEGORY: "v1/admin/payments/category/update-clg-category",

    ASSIGN_FEES: "v1/admin/payments/feeassign/save-fees",
    ASSIGN_COLLEGE_FEES: "v1/admin/payments/feeassign/save-college-fees",
    DELETE_ASSIGNED_FEES: "v1/admin/payments/feeassign/delete-fees",
    DELETE_COLLEGE_FEES: "v1/admin/payments/feeassign/delete-college-fees",

    REPORTS_DAILY_LIST: "v1/admin/payments/reports/list",
    REPORTS_COLLEGE_FEE: "v1/admin/payments/reports/list-college-fee",
    STUDENT_ORDERS: "v1/admin/payments/reports/student-orders",
    PHD_STUDENT_ORDERS: "v1/admin/payments/reports/phd-student-orders",

    LIST_PAYMENT_PAID: "v1/admin/payments/pay/payment-paid",
    LIST_PAYMENTS_TO_BE_PAY: "v1/admin/payments/pay/list-payments",
    CLG_PAYMENT_LINK: "v1/admin/payments/pay/clg-payment-link",

    LIST_PHD_YEARLY_FEES: "v1/admin/payments/fees/list-yearly-fees",
    CREATE_PHD_YEARLY_FEE: "v1/admin/payments/fees/create-yearly-fee",
    UPDATE_PHD_YEARLY_FEE: "v1/admin/payments/fees/update-yearly-fee",
    DELETE_PHD_YEARLY_FEE: "v1/admin/payments/fees/delete-yearly-fee",

    LIST_REQUEST_TYPES: "v1/admin/payments/fees/list-request-types",
    CREATE_REQUEST_TYPE: "v1/admin/payments/fees/create-request-type",
    UPDATE_REQUEST_TYPE: "v1/admin/payments/fees/update-request-type",
    DELETE_REQUEST_TYPE: "v1/admin/payments/fees/delete-request-type",
  },
  CRON: {
    TRACK_ORDER: "cron/track_orders",
    TRACK_ORDER_PHD: "cron/track_orders_phd",
  },
  QUESTION: {
    VERIFY_QSN_SUBJECT_CODE:
      "v1/admin/question/questionpreparation/verify-subject-code",
    SAVE_QUESTION_JSON:
      "v1/admin/question/questionpreparation/save-question-json",
    UPDATE_QUESTION_JSON:
      "v1/admin/question/questionpreparation/update-question-json",
    QUESTION_LIST: "v1/admin/question/questionpreparation/question-list",
    GET_QSN_JSON: "v1/admin/question/questionpreparation/get-qsn-json",
    UPDATE_APPROVAL_STATUS:
      "v1/admin/question/questionpreparation/update-approval-status",
    UPDATE_RELEASE_DATE:
      "v1/admin/question/questionpreparation/update-release-date",
    PUBLISH_QP1: "v1/admin/question/questionpreparation/publish-qp1",
    GET_QSN_PAPER: "v1/admin/question/questionpreparation/get-qsn-paper",
    EXAM_ATTENDANCE_SUBJECTS:
      "v1/admin/question/questionpreparation/exam-attendance-subjects",
  },

  UTILITIES: {
    LIST_STUDENT_CERTIFICATES: "v1/admin/util/stucert/list-all",
    GET_STUDENT_CERTIFICATE: "v1/admin/util/stucert/get",
    UPDATE_STUDENT_CERTIFICATE: "v1/admin/util/stucert/update",
    CREATE_STUDENT_CERTIFICATE: "v1/admin/util/stucert/save",

    LIST_EMPLOYEE_CERTIFICATES: "v1/admin/util/empcert/list-all",
    GET_EMPLOYEE_CERTIFICATE: "v1/admin/util/empcert/get",
    UPDATE_EMPLOYEE_CERTIFICATE: "v1/admin/util/empcert/update",
    CREATE_EMPLOYEE_CERTIFICATE: "v1/admin/util/empcert/save",

    LIST_STUDENT_TEMPLATE: "v1/admin/util/template/list-all",
    GET_STUDENT_TEMPLATE: "v1/admin/util/template/get",
    UPDATE_STUDENT_TEMPLATE: "v1/admin/util/template/update",
    CREATE_STUDENT_TEMPLATE: "v1/admin/util/template/save",

    SAVE_DAY_ORDER: "v1/admin/util/attdayorder/save",
    LIST_DAY_ORDERS: "v1/admin/util/attdayorder/list",
    DELETE_DAY_ORDER: "v1/admin/util/attdayorder/delete",
    UPDATE_DAY_ORDER: "v1/admin/util/attdayorder/update",
    DAY_ORDER_BY_BATCH: "v1/admin/util/attdayorder/by-batch",
    ACADEMIC_CALENDAR: "v1/admin/util/attdayorder/academic-calendar",

    SAVE_ATTENDANCE_PERCENTAGE: "v1/admin/util/attpercentage/save",
    LIST_ATTENDANCE_PERCENTAGES: "v1/admin/util/attpercentage/list",
    DELETE_ATTENDANCE_PERCENTAGE: "v1/admin/util/attpercentage/delete",
    UPDATE_ATTENDANCE_PERCENTAGE: "v1/admin/util/attpercentage/update",

    SAVE_SCHOLARSHIPS: "v1/admin/util/scholarship/save",
    LIST_SCHOLARSHIPS: "v1/admin/util/scholarship/list-all",
    DELETE_SCHOLARSHIPS: "v1/admin/util/scholarship/delete",
    UPDATE_SCHOLARSHIPS: "v1/admin/util/scholarship/update",

    SAVE_STUDENT_FACILITY: "v1/admin/util/studentfacility/save",
    LIST_STUDENT_FACILITY: "v1/admin/util/studentfacility/list-all",
    DELETE_STUDENT_FACILITY: "v1/admin/util/studentfacility/delete",
    UPDATE_STUDENT_FACILITY: "v1/admin/util/studentfacility/update",

    SAVE_HOSTEL: "v1/admin/util/hostel/save",
    LIST_HOSTEL: "v1/admin/util/hostel/list-all",
    DELETE_HOSTEL: "v1/admin/util/hostel/delete",
    UPDATE_HOSTEL: "v1/admin/util/hostel/update",

    SAVE_BUILDING_BLOCKS: "v1/admin/util/buildingblocks/save",
    LIST_BUILDING_BLOCKS: "v1/admin/util/buildingblocks/list",
    DELETE_BUILDING_BLOCKS: "v1/admin/util/buildingblocks/delete",
    UPDATE_BUILDING_BLOCKS: "v1/admin/util/buildingblocks/update",

    SAVE_BUILDING_ROOMS: "v1/admin/util/buildingrooms/save",
    LIST_BUILDING_ROOMS: "v1/admin/util/buildingrooms/list",
    DELETE_BUILDING_ROOMS: "v1/admin/util/buildingrooms/delete",
    UPDATE_BUILDING_ROOMS: "v1/admin/util/buildingrooms/update",

    SAVE_BUILDING_TYPES: "v1/admin/util/buildingtypes/save",
    LIST_BUILDING_TYPES: "v1/admin/util/buildingtypes/list",
    DELETE_BUILDING_TYPES: "v1/admin/util/buildingtypes/delete",
    UPDATE_BUILDING_TYPES: "v1/admin/util/buildingtypes/update",

    SAVE_STUDENT_FEE_TYPES: "v1/admin/settings/feetypes/save",
    LIST_STUDENT_FEE_TYPES: "v1/admin/settings/feetypes/list",
    DELETE_STUDENT_FEE_TYPES: "v1/admin/settings/feetypes/delete",
    UPDATE_STUDENT_FEE_TYPES: "v1/admin/settings/feetypes/update",

    SAVE_ATTENDANCE_TIME_SLOT: "v1/admin/util/atttimeslot/save",
    LIST_ATTENDANCE_TIME_SLOT: "v1/admin/util/atttimeslot/list",
    DELETE_ATTENDANCE_TIME_SLOT: "v1/admin/util/atttimeslot/delete",
    UPDATE_ATTENDANCE_TIME_SLOT: "v1/admin/util/atttimeslot/update",

    SAVE_CATEGORY_HOSTEL: "v1/admin/util/hostel/save-category",
    LIST_CATEGORY_HOSTEL: "v1/admin/util/hostel/list-category",
    DELETE_CATEGORY_HOSTEL: "v1/admin/util/hostel/delete-category",
    UPDATE_CATEGORY_HOSTEL: "v1/admin/util/hostel/update-category",

    SAVE_STAFF_HOSTEL: "v1/admin/util/hostel/save-staff",
    LIST_STAFF_HOSTEL: "v1/admin/util/hostel/list-staff",
    DELETE_STAFF_HOSTEL: "v1/admin/util/hostel/delete-staff",
    UPDATE_STAFF_HOSTEL: "v1/admin/util/hostel/update-staff",
  },

  HR: {
    V2: {
      EMPLOYEE_SAVE: "v1/admin/employee_v2/save",
      VIEW_PASSWORDS: "v1/admin/employee_v2/passwords",
      UPDATE_PERSONAL: "v1/admin/employee_v2/update-personal",
      UPDATE_COMMUNICATION: "v1/admin/employee_v2/update-communication",
      UPDATE_BANK: "v1/admin/employee_v2/update-bank",
      UPDATE_QUALI: "v1/admin/employee_v2/update-quali",
      UPDATE_RESEARCH: "v1/admin/employee_v2/update-research",
      ADD_EXPERIENCE: "v1/admin/employee_v2/add-experience",
      UPDATE_EXPERIENCE: "v1/admin/employee_v2/update-experience",
      UPDATE_EXAMINATION: "v1/admin/employee_v2/update-examination",
      UPDATE_THESIS: "v1/admin/employee_v2/update-thesis",
      UPDATE_DOCTORAL: "v1/admin/employee_v2/update-doctoral",
      LIST_EXAMINATION: "v1/admin/employee_v2/list-examination",
      LIST_EXPERIENCE: "v1/admin/employee_v2/list-experience",
      LIST_RESEARCH: "v1/admin/employee_v2/list-research",
      LIST_THESIS: "v1/admin/employee_v2/list-thesis",
      LIST_DOCTORAL: "v1/admin/employee_v2/list-doctoral",
      LIST_STAFF_SUBJECT_WITH_MARKS:
        "v1/admin/employee_v2/list-staff-subject-marks",
      UPDATE_PASSWORD: "v1/admin/employee_v2/update-pass",

      COUNT_RESEARCH_EXPERIENCE:
        "v1/admin/employee_v2/count-research-experience",
      COUNT_COLLEGE_EXPERIENCE: "v1/admin/employee_v2/count-college-experience",
      DELETE_EXPERIENCE: "v1/admin/employee_v2/delete-experience",
      DELETE_DOCTORAL: "v1/admin/employee_v2/delete-doctoral",
      DELETE_THESIS: "v1/admin/employee_v2/delete-thesis",
      DELETE_EXAMINATION: "v1/admin/employee_v2/delete-examination",
      SAVE_QUALIFICATION: "v1/admin/employee_v2/save-qualification",
      UPDATE_QUALIFICATION: "v1/admin/employee_v2/update-qualification",
      LIST_QUALIFICATION: "v1/admin/employee_v2/list-qualification",
      DELETE_QUALIFICATION: "v1/admin/employee_v2/delete-qualification",
    },

    EMP_INCHARGE: {
      SAVE_INCHARGE: "v1/admin/employee_v3/save",
      UPDATE_INCHARGE: "v1/admin/employee_v3/update",
      INCHARGE_COUNT: "v1/admin/employee_v3/count-incharge",
      LIST_INCHARGE: "v1/admin/employee_v3/list",
      DELETE_INCHARGE: "v1/admin/employee_v3/delete",
      SAVE_POSITION: "v1/admin/employee_v3/save-personalposition",
      UPDATE_POSITION: "v1/admin/employee_v3/update-personalposition",
      LIST_POSITION: "v1/admin/employee_v3/list-personalposition",
      DELETE_POSITION: "v1/admin/employee_v3/delete-personalposition",

      SAVE_RESEARCH: "v1/admin/employee_v3/save-research",
      UPDATE_RESEARCH: "v1/admin/employee_v3/update-research",
      LIST_RESEARCH: "v1/admin/employee_v3/list-research",
      DELETE_RESEARCH: "v1/admin/employee_v3/delete-research",

      SAVE_RESEARCH_PROJECTS: "v1/admin/employee_v3/save-researchprojects",
      UPDATE_RESEARCH_PROJECTS: "v1/admin/employee_v3/update-researchprojects",
      LIST_RESEARCH_PROJECTS: "v1/admin/employee_v3/list-researchprojects",
      DELETE_RESEARCH_PROJECTS: "v1/admin/employee_v3/delete-researchprojects",

      SAVE_AWARDS: "v1/admin/employee_v3/save-awards",
      UPDATE_AWARDS: "v1/admin/employee_v3/update-awards",
      LIST_AWARDS: "v1/admin/employee_v3/list-awards",
      DELETE_AWARDS: "v1/admin/employee_v3/delete-awards",

      SAVE_PRESENTATION: "v1/admin/employee_v3/save-presentation",
      UPDATE_PRESENTATION: "v1/admin/employee_v3/update-presentation",
      LIST_PRESENTATION: "v1/admin/employee_v3/list-presentation",
      DELETE_PRESENTATION: "v1/admin/employee_v3/delete-presentation",

      SAVE_PUBLICATION: "v1/admin/employee_v3/save-publication",
      UPDATE_PUBLICATION: "v1/admin/employee_v3/update-publication",
      LIST_PUBLICATION: "v1/admin/employee_v3/list-publication",
      DELETE_PUBLICATION: "v1/admin/employee_v3/delete-publication",

      SAVE_DEPT_EXAMINATION: "v1/admin/employee_v3/save-deptexam",
      UPDATE_DEPT_EXAMINATION: "v1/admin/employee_v3/update-deptexam",
      LIST_DEPT_EXAMINATION: "v1/admin/employee_v3/list-deptexam",
      DELETE_DEPT_EXAMINATION: "v1/admin/employee_v3/delete-deptexam",

      SAVE_ACHIEVEMENT: "v1/admin/employee_v3/save-achievement",
      UPDATE_ACHIEVEMENT: "v1/admin/employee_v3/update-achievement",
      LIST_ACHIEVEMENT: "v1/admin/employee_v3/list-achievement",
      DELETE_ACHIEVEMENT: "v1/admin/employee_v3/delete-achievement",

      SAVE_FACULTY: "v1/admin/employee_v3/save-faculty",
      UPDATE_FACULTY: "v1/admin/employee_v3/update-faculty",
      LIST_FACULTY: "v1/admin/employee_v3/list-faculty",
      DELETE_FACULTY: "v1/admin/employee_v3/delete-faculty",

      SAVE_TRAINING: "v1/admin/employee_v3/save-training",
      UPDATE_TRAINING: "v1/admin/employee_v3/update-training",
      LIST_TRAINING: "v1/admin/employee_v3/list-training",
      DELETE_TRAINING: "v1/admin/employee_v3/delete-training",

      SAVE_VISIT: "v1/admin/employee_v3/save-visit",
      UPDATE_VISIT: "v1/admin/employee_v3/update-visit",
      LIST_VISIT: "v1/admin/employee_v3/list-visit",
      DELETE_VISIT: "v1/admin/employee_v3/delete-visit",

      SAVE_SUSPENSION: "v1/admin/employee_v3/save-memo",
      UPDATE_SUSPENSION: "v1/admin/employee_v3/update-memo",
      LIST_SUSPENSION: "v1/admin/employee_v3/list-memo",
      DELETE_SUSPENSION: "v1/admin/employee_v3/delete-memo",

      SAVE_SECTION_TRANSFER: "v1/admin/employee_v3/save-section-transfer",
      UPDATE_SECTION_TRANSFER: "v1/admin/employee_v3/update-section-transfer",
      LIST_SECTION_TRANSFER: "v1/admin/employee_v3/list-section-transfer",
      DELETE_SECTION_TRANSFER: "v1/admin/employee_v3/delete-section-transfer",

      DASHBOARD_STAFF_COUNT: "v1/admin/employee_v3/list-staff-count",
      DASHBOARD_STAFF_TABLE: "v1/admin/employee_v3/list-staff-table",
    },

    SAVE_GRADE: "v1/admin/hrmgt/grade/save_grade",
    LIST_GRADES: "v1/admin/hrmgt/grade/list_gardes",
    REMOVE_GRADE: "v1/admin/hrmgt/grade/remove_grade",
    UPDATE_GRADE: "v1/admin/hrmgt/grade/update_grade",
    SAVE_BREAKUP_HEAD: "v1/admin/payroll/break/save_breakuphead",
    LIST_BREAKUP_HEADS: "v1/admin/payroll/break/list_breakuphead",
    REMOVE_BREAKUP_HEAD: "v1/admin/payroll/break/remove_breakuphead",
    UPDATE_BREAKUP_HEAD: "v1/admin/payroll/break/update_breakuphead",

    EMPLOYEE_NEW_CODE: "v1/admin/hrmgt/employee/employee_code",
    SAVE_EMPLOYEE: "v1/admin/hrmgt/employee/save_data",
    LIST_EMPLOYEES: "v1/admin/hrmgt/employee/list_data",
    UPDATE_EMPLOYEES_INCREMENT_DATES:
      "v1/admin/hrmgt/employee/update_increment_dates",

    REMOVE_EMPLOYEE: "v1/admin/hrmgt/employee/remove_data",
    CHANGE_EMPLOYEE_STATUS: "v1/admin/hrmgt/employee/status_chagne",
    UPDATE_EMPLOYEE: "v1/admin/hrmgt/employee/update_data",
    UPDATE_PERSONAL_INFO: "v1/admin/hrmgt/employee/update_personal_info",
    UPDATE_EMPLOYEE_SALARY: "v1/admin/hrmgt/employee/update_salary",
    EMPLOYEE_BY_CODE: "v1/admin/hrmgt/employee/employee_by_code",

    SAVE_BIOMETRIC_DEVICE: "v1/admin/hrmgt/device/save_data",
    LIST_BIOMETRIC_DEVICES: "v1/admin/hrmgt/device/list_data",
    REMOVE_BIOMETRIC_DEVICE: "v1/admin/hrmgt/device/remove_data",
    UPDATE_BIOMETRIC_DEVICE: "v1/admin/hrmgt/device/update_data",

    LIST_LEAVE: "v1/admin/hrmgt/leave/list_data",
    SAVE_HOLIDAY: "v1/admin/hrmgt/holiday/save_data",
    LIST_HOLIDAYS: "v1/admin/hrmgt/holiday/list_data",
    REMOVE_HOLIDAY: "v1/admin/hrmgt/holiday/remove_data",
    SAVE_SUNDAYS: "v1/admin/hrmgt/holiday/save_sundays",

    LIST_QUAL_CATEGORY: "v1/admin/hrmgt/qualification/list_quali_category",

    SAVE_HOSTEL_HOLIDAY: "v1/admin/hrmgt/holiday/save_hostel_data",
    LIST_HOSTEL_HOLIDAYS: "v1/admin/hrmgt/holiday/list_hostel_data",
    REMOVE_HOSTEL_HOLIDAY: "v1/admin/hrmgt/holiday/remove_hostel_data",
    SAVE_HOSTEL_SUNDAYS: "v1/admin/hrmgt/holiday/save_hostel_sundays",

    ATT_MONTH_LOG: "v1/admin/hrmgt/attendance/att_month_log",
    SAVE_BRANCH: "v1/admin/hrmgt/branch/save-data",
    LIST_BRANCHES: "v1/admin/hrmgt/branch/list-data",
    REMOVE_BRANCHE: "v1/admin/hrmgt/branch/remove-data",
    UPDATE_BRANCHE: "v1/admin/hrmgt/branch/update-data",
    SAVE_DEPARTMENT: "v1/admin/hrmgt/department/save-data",
    LIST_DEPARTMENTS: "v1/admin/hrmgt/department/list-data",
    LIST_STDEPARTMENTS: "v1/admin/student_dept/list-data",
    REMOVE_DEPARTMENT: "v1/admin/hrmgt/department/remove-data",
    UPDATE_DEPARTMENT: "v1/admin/hrmgt/department/update-data",

    SAVE_TYPE: "v1/admin/hrmgt/type/save-data",
    LIST_TYPE: "v1/admin/hrmgt/type/list-data",
    REMOVE_TYPE: "v1/admin/hrmgt/type/remove-data",
    UPDATE_TYPE: "v1/admin/hrmgt/type/update-data",

    LIST_CLASSIFICATION: "v1/admin/hrmgt/type/list-classification",
    SAVE_CLASSIFICATION: "v1/admin/hrmgt/type/save-classification",
    UPDATE_CLASSIFICATION: "v1/admin/hrmgt/type/update-classification",
    REMOVE_CLASSIFICATION: "v1/admin/hrmgt/type/remove-classification",

    LIST_CATEGORY_EMPLOYEE: "v1/admin/hrmgt/type/list-employeecategories",
    SAVE_CATEGORY_EMPLOYEE: "v1/admin/hrmgt/type/save-employeecategories",
    UPDATE_CATEGORY_EMPLOYEE: "v1/admin/hrmgt/type/update-employeecategories",
    REMOVE_CATEGORY_EMPLOYEE: "v1/admin/hrmgt/type/remove-employeecategories",

    SAVE_LEAVE_TYPE: "v1/admin/hrmgt/leavetype/save-data",
    LIST_LEAVE_TYPE: "v1/admin/hrmgt/leavetype/list-data",
    REMOVE_LEAVE_TYPE: "v1/admin/hrmgt/leavetype/remove-data",
    UPDATE_LEAVE_TYPE: "v1/admin/hrmgt/leavetype/update-data",
    EMPLOYEE_LEAVE_LIST: "v1/admin/hrmgt/leavetype/get-employee-allowedleaves",

    SAVE_DESIGNATION: "v1/admin/hrmgt/designation/save-data",
    LIST_DESIGNATIONS: "v1/admin/hrmgt/designation/list-data",
    REMOVE_DESIGNATION: "v1/admin/hrmgt/designation/remove-data",
    UPDATE_DESIGNATION: "v1/admin/hrmgt/designation/update-data",

    SAVE_SHIFT: "v1/admin/hrmgt/shift/save_data",
    LIST_SHIFT: "v1/admin/hrmgt/shift/list_data",
    UPDATE_SHIFT: "v1/admin/hrmgt/shift/update_data",
    REMOVE_SHIFT: "v1/admin/hrmgt/shift/remove_data",

    LAST_DEVICEID: "v1/admin/hrmgt/attendance/last_inserted_deviceid",
    INSERT_DEVICE_DATAS: "v1/admin/hrmgt/attendance/device_monthly_data",

    SAVE_ATTENDANCE: "v1/admin/hrmgt/employee/save-data",
    LIST_ATTENDANCE: "v1/admin/hrmgt/employee/list-data",
    REMOVE_ATTENDANCE: "v1/admin/hrmgt/employee/remove-data",
    UPDATE_ATTENDANCE: "v1/admin/hrmgt/employee/update-data",
    EXPORT_EMPLOYEE_REPORT: "v1/admin/hrmgt/employee/export-employee-data",

    ATTENDANCE_REPORTS: "v1/admin/hrmgt/employee/bio-report",
    ATTENDANCE_REPORTS_COUNT: "v1/admin/hrmgt/employee/bio-report-count",
    LIST_COMMUNITY: "v1/admin/hrmgt/employee/community",

    SAVE_SECTION: "v1/admin/hrmgt/section/save-data",
    LIST_SECTION: "v1/admin/hrmgt/section/list-data",
    REMOVE_SECTION: "v1/admin/hrmgt/section/remove-data",
    UPDATE_SECTION: "v1/admin/hrmgt/section/update-data",

    SAVE_INCHARGE: "v1/admin/hrmgt/incharge/save-data",
    LIST_INCHARGE: "v1/admin/hrmgt/incharge/list-data",
    REMOVE_INCHARGE: "v1/admin/hrmgt/incharge/remove-data",
    UPDATE_INCHARGE: "v1/admin/hrmgt/incharge/update-data",
  },

  LESSON_PLAN: {
    SAVE_COURSE_SUBJECT: "v1/admin/lessonPlanning/save-course-subject",
    UPDATE_COURSE_SUBJECT: "v1/admin/lessonPlanning/update-course-subject",
    LIST_COURSE_SUBJECT: "v1/admin/lessonPlanning/list-course-subject",
    COUNT_COURSE_SUBJECT: "v1/admin/lessonPlanning/count-course-subject",

    SAVE_UNITS: "v1/admin/lessonPlanning/save-unit",
    UPDATE_UNITS: "v1/admin/lessonPlanning/update-units",
    UPDATE_DESCRIPTION: "v1/admin/lessonPlanning/update-description",
    LIST_UNITS: "v1/admin/lessonPlanning/list-unit",
    DELETE_UNITS: "v1/admin/lessonPlanning/delete-unit",

    SAVE_UNIT_TOPICS: "v1/admin/lessonPlanning/save-unit-topics",
    UPDATE_UNIT_TOPICS: "v1/admin/lessonPlanning/update-unit-topics",
    DELETE_UNIT_TOPICS: "v1/admin/lessonPlanning/delete-unit-topics",
    LIST_UNIT_TOPICS: "v1/admin/lessonPlanning/list-unit-topics",

    LIST_WEEK_SCHEDULE: "v1/admin/lessonPlanning/list-week-schedule",
    SAVE_STAFF_PLAN: "v1/admin/lessonPlanning/save-staff-plan",
    LIST_STAFF_PLAN: "v1/admin/lessonPlanning/list-staff-plan",
    LIST_STAFF_ASSESSMENT_PLAN:
      "v1/admin/lessonPlanning/list-staff-assessment-plan",

    LIST_ASSESSMENT_TYPES: "v1/admin/lessonPlanning/list-assessment-types",
    SAVE_STAFF_ASSESSMENT: "v1/admin/lessonPlanning/save-staff-assessment",

    SAVE_OTHER_TOPICS: "v1/admin/lessonPlanning/save-other-topics",
    UPDATE_OTHER_TOPICS: "v1/admin/lessonPlanning/update-other-topics",
    DELETE_OTHER_TOPICS: "v1/admin/lessonPlanning/delete-other-topics",
    LIST_OTHER_TOPICS: "v1/admin/lessonPlanning/list-other-topics",
  },

  DASHBOARD: {
    FIRST_LINE_COUNT: "v1/admin/dashboard/first-line-counts",
    STUDENTS_BIRTHDAY_LIST: "v1/admin/dashboard/students-birthday-list",
    STAFFS_BIRTHDAY_LIST: "v1/admin/dashboard/staffs-birthday-list",
    STAFFS_LEAVE_LIST: "v1/admin/dashboard/staffs-leave-list",
    STAFFS_LEAVE_DETAILS: "v1/admin/dashboard/staffs-leave-detail",
    STAFFS_BIRTHDAY_NEW: "v1/admin/dashboard/staffs-birthday-new",

    STUDENTS_COUNT: "v1/admin/dashboard/students-count",
    TODAY_DAY_ORDER: "v1/admin/dashboard/today-day-order",
    TODAY_ATTENDANCE_COUNT: "v1/admin/dashboard/today-attendance-count",
    TODAYS_ABSENTEES_COUNT: "v1/admin/dashboard/todays-absentees-count",
    DATEWISE_ABSENTEES_COUNT: "v1/admin/dashboard/date-wise-absentees-count",

    TODAYS_UNATTENDED_CLASS: "v1/admin/dashboard/todays-unattended-class",
    TODAY_TIME_TABLE: "v1/admin/dashboard/time-table-today",

    TODAY_COLLECTION_SUMMARY: "v1/admin/dashboard/today-collection-summary",
    ONLINE_COLLECTION_SUMMARY: "v1/admin/dashboard/online-collection-summary",
  },
  SUSPENSION: {
    SAVE: "v1/admin/student/suspension/save",
    LIST: "v1/admin/student/suspension/list",
    REVOKE: "v1/admin/student/suspension/revoke",
    DELETE: "v1/admin/student/suspension/delete",
  },
};
